export default {
  methods: {
  	changeAccountGdpr ({ id, serialNumber, type, gdpr }) {
      const newGdpr = gdpr ? 0 : 1
      if (type == 'bank-account') {
        let isNotApplied = false
        for (let i = 0; i < this.bankAcc.body.length; i++) {
          if (this.bankAcc.body[i][0] == serialNumber) {
            const item = this.bankAcc.body[i]
            for (let z = 0; z < item.length; z++) {
              if (typeof item[z] === 'object' && item[z] !== null) {
                if (item[z].type && item[z].type == 'account-apply-btn') {
                  isNotApplied = true
                }
              }
            }
            if (isNotApplied) {
              for (let z = 0; z < item.length; z++) {
                if (typeof item[z] === 'object' && item[z] !== null) {
                  if (item[z].type && item[z].type == 'account-gdpr') {
                    item[z].gdpr = newGdpr
                    this.bankAcc.body[i] = item
                  }   
                  if (item[z].type && item[z].type == 'account-apply-btn') {
                    item[z].gdpr = newGdpr
                    this.bankAcc.body[i] = item
                  }
                }
              } 
              axios.post(process.env.VUE_APP_BACKEND_API_URL + 'accounts/gdpr/' + id + '/' + newGdpr)
		        .then(({ data }) => {
		          if (data) {
		            console.log(data)
		          }
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
            }
          }
        }
      } else if (type == 'merchant-account') {
      	let isNotApplied = false
        for (let i = 0; i < this.merchantAcc.body.length; i++) {
          if (this.merchantAcc.body[i][0] == serialNumber) {
            const item = this.merchantAcc.body[i]
            for (let z = 0; z < item.length; z++) {
              if (typeof item[z] === 'object' && item[z] !== null) {
                if (item[z].type && item[z].type == 'account-apply-btn') {
                  isNotApplied = true
                }
              }
            }
            if (isNotApplied) {
              for (let z = 0; z < item.length; z++) {
                if (typeof item[z] === 'object' && item[z] !== null) {
                  if (item[z].type && item[z].type == 'account-gdpr') {
                    item[z].gdpr = newGdpr
                    this.merchantAcc.body[i] = item
                  }   
                  if (item[z].type && item[z].type == 'account-apply-btn') {
                    item[z].gdpr = newGdpr
                    this.merchantAcc.body[i] = item
                  }
                }
              } 
              axios.post(process.env.VUE_APP_BACKEND_API_URL + 'accounts/gdpr/' + id + '/' + newGdpr)
		        .then(({ data }) => {
		          if (data) {
		            console.log(data)
		          }
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
            }
          }
        }
      }
    }
  }
}