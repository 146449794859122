<template>
  <overlay-scrollbars class="page page-accounts" ref="os">{{ triedBuy }}
    <Compliance :class="{ 'blur_5': invoice.show }" />
    <div class="box box_selectedAcc" :class="{ 'blur_5': invoice.show }">
      <div ref="bankAccountsHeaderDiv" class="header">
        <div class="title">INDIVIDUALLY SELECTED ACCOUNTS FOR YOUR TYPE OF BUSINESS</div>
      </div>
      <div class="content">
        <div class="block block__bankAccount">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
          </div>
          <Table
            :options="bankAcc" :key="bankAccKey"
            @apply="apply" @change-page="getBankAccounts" @scroll-top="toElTop" @change-account-gdpr="changeAccountGdpr"
          /> 
        </div>
        <div class="block block__merchantAccount">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
          </div>
          <Table
            :options="merchantAcc" :key="merchantAccKey"
            @apply="apply" @change-page="getMerchantAccounts" @scroll-top="toElTop" @change-account-gdpr="changeAccountGdpr"
          />
        </div>
      </div>
    </div>
    <div v-if="invoice.show" class="wrapper">
      <div class="box box_invoice">
        <div class="header">
          <div class="header_left">
            <div class="title">Subscription</div>
            <div class="subtitle">One year platform infrastructure entrance & use</div>
          </div>
          <div class="header_center">
            <div v-if="invoice.pdfUrl" class="doc" :key="pdfObjectKey">
              <div class="btn doc_close" @click="closeInvoicePdf"><Close /></div>
              <object><embed :src="invoice.pdfUrl" /></object>
            </div>
            <div v-else-if="userPackage && !triedBuy" class="doc box box__form">
              <!-- <div class="header"> -->
                <!-- <div class="title">Account packages</div> -->
              <!-- </div> -->
              <div class="body" :class="`body__${step}`">
                <!-- <template v-if="step === 'main'"> -->
                  <div class="row">
                    <!-- <div class="label">Packages</div> -->
                    <div class="item item__half item__half--l">
                      <div class="inpt inpt__packageName w100" v-text="userPackage.name"/>
                      <!-- <select name="package" class="inpt w100" @input="selectPackage">
                        <option
                          v-for="pack in packages" :key="pack.id"
                          :value="pack.id"
                          :selected="packageSelected && packageSelected.id === pack.id"
                          v-text="pack.name"
                        />
                      </select> -->
                    </div>
                    <div class="item item__half item__half--r">
                      <div class="inpt inpt__price w100">
                        <div class="val">{{ userPackage ? +userPackage.priceSelected.value : '--' }}</div>
                        <select v-if="userPackage.price.length && paymentStatus" class="btn btn__currency" @input="selectPackagePrice">
                          <option
                            v-for="(price, index) in userPackage.price"
                            :key="index"
                            :value="price.id"
                            :selected="price.id === userPackage.priceSelected.id"
                            v-text="price.currency.code"
                          />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="subtxt risk-title">
                      Pre-check type of risk:
                      <Info pos="tr" txt="Compliance risk status, getting type including a provided date by client and isn’t a final . Can be change after full Compliance review" />
                    </div>
                    <div class="txt">
                      <span v-if="showCalculatedRisk == 0">{{ risk }}</span>
                      <span v-else>{{ calculatedRiskName }}</span> 
                    </div>
                  </div>
                  <!-- <template v-if="packageSelected"> -->
                    <!-- <div class="row">
                      <div class="label">Price</div>
                    </div> -->
                    <div class="row row__services">
                      <div class="h2">What's included in your plan?</div>
                      <!-- <div class="label">List of services</div> -->
                      <!-- <div class="item"> -->
                        <div class="inpt inpt__services w100" v-html="userPackage.description"/>
                      <!-- </div> -->
                    </div>
                    <div class="title__btns">Payment Method</div>
                    <div class="row row__btns">
                      <div class="btn" @click="toStepCard">Card</div>
                      <div class="btn" @click="toStepWire">Wire</div>
                    </div>
                  <!-- </template> -->
                <!-- </template> -->
                <!-- <template v-if="step === 'card' || step === 'wire'">
                  <div class="row row__back">
                    <div class="btn" @click="step = 'main'">&#129044; Back</div>
                  </div>
                  <div class="row row__info">
                    {{ packageSelected.name }} - {{ packageSelected.priceSelected.val }} {{ packageSelected.priceSelected.currency_name }}
                  </div>
                </template> -->
                <template v-if="step === 'card'">
                  <div class="row">
                    <!-- <div class="label">Receiving country</div> -->
                    <div class="item item__half item__half--l">
                      <select v-if="countries" class="inpt inpt__country" v-model="card.country">
                        <option
                          value="null"
                          :selected="!card.country"
                          class="hide" disabled>Select country</option>
                        <option
                          v-for="(country, index) in countries"
                          :key="index"
                          :value="country.code"
                          :selected="country.code === card.country"
                          v-text="country.name"
                        />
                      </select>
                    </div>
                    <div class="item item__half item__half--r">
                      <input
                        type="text"
                        name="fullname"
                        class="inpt w100"
                        placeholder="Enter your fullname"
                        v-model="card.fullname"
                      >
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="label">Receiver`s fullname</div>
                    <input type="text" name="fullname" class="inpt w100" v-model="card.fullname">
                  </div> -->
                  <div class="row">
                    <!-- <div class="label">Receiving card number</div> -->
                    <div class="item item__half item__half--l">
                      <input
                        type="text"
                        name="number"
                        class="inpt w100"
                        placeholder="Enter your card number"
                        v-model="card.number"
                      >
                    </div>
                    <div class="item item__half item__half--r">
                      <input
                        type="text"
                        name="exp_date"
                        class="inpt w100"
                        placeholder="mm/yy"
                        v-model="card.exp_date"
                      >
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="label">Expiration date</div>
                    <input type="text" name="exp_date" class="inpt" v-model="card.exp_date">
                  </div> -->
                  <div class="row row__buy">
                    <div class="btn" @click="buy">Buy</div>
                  </div>
                  <div class="row">
                    <CheckBox
                      :state="card.confirmation"
                      :label="'Agree with Terms and conditions, Privacy Policy and Refund and Cross-chain policy'"
                      @changeState="card.confirmation = !card.confirmation"
                    />
                  </div>
                  <!-- <div class="row row__btns">
                    <div class="btn">Buy</div>
                  </div> -->
                </template>
                <template v-if="step === 'wire'">
                  <div class="row row__invoice">
                    <object><embed :src="getPackageInvoiceFile"/></object>
                  </div>
                  <div class="row row__btns">
                    <div class="btn" @click="downloadInvoice">Download</div>
                  </div>
                </template>
              </div>
            </div>
            <div v-else-if="triedBuy" class="doc box box__info">
              <div class="body">
                <h2>Funds will be written off after data verification</h2>
              </div>
            </div>
          </div>
          <div class="header_right">
            <div @click="$refs.invoiceFile.click()" class="btn btn_uploadPayment">Upload</div>
            <input accept="application/pdf" type="file" @change="handleFileInvoiceChange($event)" ref="invoiceFile" style="display: none">
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from '@/components/elem/Info.vue'
import Compliance from '@/components/elem/Compliance.vue'
import Table from '@/components/elem/Table.vue'
import CheckBox from '@/components/elem/CheckBox.vue'

import Bank2 from '@/components/img/Bank2.vue'
import CreditCards from '@/components/img/CreditCards.vue'
import Close from '@/components/img/Close.vue'
import Pagination from 'laravel-vue-pagination'
import AccountGdpr from '@/mixins/account_gdpr.js'

export default {
  mixins: [
    AccountGdpr
  ],
  name: 'Accounts',
  components: {
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    Close,
    Pagination,
    CheckBox
  },
  data: () => ({
    invoiceFile: null,
    bankAccountsData: {},
    bankAccKey: 0,
    merchantAccountsData: {},
    merchantAccKey: 0,
    showCalculatedRisk: false,
    bankAcc: {
      colsWidth: ['3%', '10%', '10%', '10%', '11%', '14%', '14%', '5%', '11%', '10%'],
      header: [
        { ttl: '#', info: null },
        {
          ttl: 'Name',
          info: {
            pos: 'tr',
            txt: 'The names will be available to you after passing Compliance'
          }
        },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Account', info: null },
        {
          ttl: 'Open/Integration fee',
          info: {
            pos: 'tr',
            txt: 'All Fee is a public providing by Financial Institutions.<br/> The Fee, depand Client type of business and legal structure'
          }
        },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Gdpr', info: null },
        {
          ttl: 'Onboarding time',
          info: {
            pos: 'tl',
            txt: 'Actual deadlines, start from date of  providing  company packege of documents'
          }
        },
        { ttl: 'Request', info: null },
      ],
      body: [],
      paginationData: null
    },
    merchantAcc: {
      colsWidth: ['3%', '10%', '10%', '10%', '11%', '14%', '14%', '5%', '11%', '10%'],
      header: [
        { ttl: '#', info: null },
        {
          ttl: 'Name',
          info: {
            pos: 'tr',
            txt: 'The names will be available to you after passing Compliance'
          }
        },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Zone', info: null },
        {
          ttl: 'Open/Integration fee',
          info: {
            pos: 'tr',
            txt: 'All Fee is a public providing by Financial Institutions.<br/> The Fee, depand Client type of business and legal structure'
          }
        },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Gdpr', info: null },
        {
          ttl: 'Onboarding time',
          info: {
            pos: 'tl',
            txt: 'Actual deadlines, start from date of  providing  company packege of documents'
          }
        },
        { ttl: 'Request', info: null },
      ],
      body: [],
      paginationData: null
    },
    invoice: {
      show: false,
      data: null,
      pdfUrl: null,
    },
    paymentStatus: false,
    pdfObjectKey: 0,
    // packages: [
    //   {
    //     id: 1,
    //     name: 'Low package',
    //     slug: 'low',
    //     price: [
    //       { currency_slug: 'eur', currency_name: 'EUR', val: 299 },
    //       { currency_slug: 'usd', currency_name: 'USD', val: 329 },
    //     ]
    //   },
    //   {
    //     id: 2,
    //     name: 'Low+ package',
    //     slug: 'low_plus',
    //     price: [
    //       { currency_slug: 'eur', currency_name: 'EUR', val: 399 },
    //       { currency_slug: 'usd', currency_name: 'USD', val: 429 },
    //     ]
    //   },
    //   {
    //     id: 3,
    //     name: 'Middle package',
    //     slug: 'middle',
    //     price: [
    //       { currency_slug: 'eur', currency_name: 'EUR', val: 499 },
    //       { currency_slug: 'usd', currency_name: 'USD', val: 539 },
    //     ]
    //   },
    //   {
    //     id: 4,
    //     name: 'Middle+ package',
    //     slug: 'middle_plus',
    //     price: [
    //       { currency_slug: 'eur', currency_name: 'EUR', val: 699 },
    //       { currency_slug: 'usd', currency_name: 'USD', val: 739 },
    //     ]
    //   },
    //   {
    //     id: 5,
    //     name: 'High package',
    //     slug: 'high',
    //     price: [
    //       { currency_slug: 'eur', currency_name: 'EUR', val: 999 },
    //       { currency_slug: 'usd', currency_name: 'USD', val: 1049 },
    //     ]
    //   },
    //   {
    //     id: 6,
    //     name: 'High+ package',
    //     slug: 'high_plus',
    //     price: [
    //       { currency_slug: 'eur', currency_name: 'EUR', val: 1299 },
    //       { currency_slug: 'usd', currency_name: 'USD', val: 1369 },
    //     ]
    //   },
    // ],
    // packageSelected: {
    //   id: null,
    //   name: null,
    //   price: null,
    //   priceSelected: null,
    //   services: null
    // },
    userPackage: null,
    step: 'main',
    countries: null,
    card: {
      country: null,
      fullname: null,
      number: null,
      exp_date: null,
      confirmation: false
    },
    triedBuy: null
  }),
  computed: {
    getPackageInvoiceFile () {
      return this.userPackage && this.userPackage.invoice_file_path
        ? `${process.env.VUE_APP_BACKEND_URL}/storage/${this.userPackage.invoice_file_path}`
        : `/files/invoice_${this.userPackage.id}.pdf`
    }
  },
  mounted() {
    this.invoice.show = this.$route.params.invoice
  },
  created() {
    this.$store.dispatch('setUser')
    this.getPaymentStatus()
    this.getBankAccounts()
    this.getMerchantAccounts()
    this.getUserPackage()
  },
  methods: {
    getUserPackage () {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'user/tariff-package')
        .then(({ data }) => {
          data.data.priceSelected = data.data.price.length ? data.data.price[0] : null
          data.data.description = data.data.description ||
            'Individual List <br> Bank Selection Assistant <br> Direct Bank Introduction <br> Bank Account Pre-Approval'
          this.userPackage = data.data
        })
        .catch(error => console.log(error))
    },
    // selectPackage (event) {
    //   const element = event.target
    //   if (element.value) {
    //     const pack = this.packages.find(el => el.id === +element.value)

    //     this.packageSelected = {
    //       id: pack.id,
    //       name: pack.name,
    //       slug: pack.slug,
    //       services: pack.services,
    //       price: pack.price,
    //       priceSelected: pack.price[0]
    //     }
    //   }
    // },
    selectPackagePrice (event) {
      const element = event.target
      if (element.value) {
        this.userPackage.priceSelected = this.userPackage.price.find(el => el.id === +element.value)
      }
    },
    buy () {
      for (const key in this.card) {
        if (Object.hasOwnProperty.call(this.card, key) && !this.card[key]) {
          switch (key) {
            case 'country': return this.$noty.error('Please select a country')
            case 'fullname': return this.$noty.error('Please write your full name')
            case 'number': return this.$noty.error('Please write your card number')
            case 'exp_date': return this.$noty.error('Please write card expiration date')
            case 'confirmation':
              return this.$noty.error('Please confirm that you got acquainted with the rules and conditions')
          }
        }
      }

      this.card.number = this.card.number.replace(/\D/g, '');

      const data = {
        package: this.userPackage,
        card: this.card
      }
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/buy-package', data)
        .then(() => {
          this.triedBuy = true
        })
        .catch(error => console.log(error))
    },
    toStepCard () {
      this.getCountries()
      this.step = 'card'
    },
    toStepWire () {
      this.step = 'wire'
    },
    getCountries () {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/countries')
        .then(({ data }) => {
          this.countries = data
        })
        .catch(error => console.log(error))
    },
    forceFileDownload (response){
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'invoice.pdf')
      document.body.appendChild(link)
      link.click()
    },
    downloadInvoice () {
      let link = document.createElement('a')
      link.href = this.getPackageInvoiceFile
      link.target = '_blank'
      link.download = 'invoice.pdf'
      link.dispatchEvent(new MouseEvent('click'))

      // axios({
      //   method: 'get',
      //   url: `/files/invoice_${this.userPackage.id}.pdf`,
      //   responseType: 'arraybuffer'
      // })
      // .then(response => {
      //   this.forceFileDownload(response)
      // })
      // .catch(() => console.log('error occured'))
    },
    closeInvoicePdf () {
      if (this.paymentStatus) {
        this.invoice.show = false
      }
    },
    getPaymentStatus () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'user/payment-status')
        .then(function (response) {
          that.paymentStatus = (response.data.payment_status === 1) ? true : false
          if (response.data.payment_status === 0) {
            that.invoice.show = true
          } else {
            that.invoice.show = false
          }
          if (response.data.invoice) {
            that.invoice.data = response.data.invoice
            that.invoice.pdfUrl = process.env.VUE_APP_BACKEND_URL + '/storage/invoices/' + response.data.invoice.file
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getBankAccounts(page = 1) {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'accounts/bank-accounts?page=' + page)
        .then(function (response) {
          that.bankAcc.paginationData = that.bankAccountsData = response.data
          that.bankAcc.body = []
          that.bankAccountsData.data.forEach(el => {
            const applyText = (el.applied) ? 'Applied' : 'Apply'
            const applyBtn = { type: 'account-apply-btn', html: applyText, applyType: 'bank-account', id: el.id, gdpr: el.gdpr }
            const gdpr = { id: el.id, type: 'account-gdpr', gdpr: el.gdpr, serialNumber: el.serial_number, applyType: 'bank-account' }
            if (!el.applied) {
              that.bankAcc.body.push([
                el.serial_number, el.bank_name, el.country_code, el.account_type,
                that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, el.onboarding_time_text, applyBtn
              ])
            } else {
                that.bankAcc.body.push([
                el.serial_number, el.bank_name, el.country_code, el.account_type,
                that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, { type: 'deadline_2', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, applyType: 'bank-account', id: el.id }
              ])
            }
          })
          that.$refs.bankAccountsHeaderDiv.scrollIntoView({behavior: 'smooth'});
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantAccounts(page = 1) {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'accounts/merchant-accounts?page=' + page)
        .then(function (response) {
          that.merchantAcc.paginationData = that.merchantAccountsData = response.data
          that.merchantAcc.body = []
          that.merchantAccountsData.data.forEach(el => {
            const applyText = (el.applied) ? 'Applied' : 'Apply'
            const applyBtn = { type: 'account-apply-btn', html: applyText, applyType: 'merchant-account', id: el.id, gdpr: el.gdpr }
            const gdpr = { id: el.id, type: 'account-gdpr', gdpr: el.gdpr, serialNumber: el.serial_number, applyType: 'merchant-account' }
            if (!el.applied) {
              that.merchantAcc.body.push([
                el.serial_number, el.license_type, el.country_code, el.zone_type,
                that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, el.onboarding_time_text, applyBtn
              ])
            } else {
              that.merchantAcc.body.push([
                el.serial_number, el.license_type, el.country_code, el.zone_type,
                that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, { type: 'deadline_2', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, applyType: 'merchant-account', id: el.id }
              ])
            }
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    apply (payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'accounts/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          if (payload.applyType == 'bank-account') {
            const data = that.bankAccountsData.data
            for (var i = 0; i < data.length; i++) {
              const el = data[i]
              const gdpr = { id: el.id, type: 'account-gdpr', gdpr: el.gdpr, serialNumber: el.serial_number, applyType: 'bank-account' }
              if (el.id === payload.id) {
                this.$noty.success("Apply status was successfully changed")
                if (!res.applied) {
                  that.bankAcc.body[i] = [
                    el.serial_number, el.bank_name, el.country_code, el.account_type,
                    that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, el.onboarding_time_text, { type: 'account-apply-btn', html: 'Apply', applyType: 'bank-account', id: el.id, gdpr: el.gdpr }
                  ]
                } else {
                  that.bankAcc.body[i] = [
                    el.serial_number, el.bank_name, el.country_code, el.account_type,
                    that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, { type: 'deadline_2', days: res.time_left.days_number, days_text: res.time_left.days_text, hours: res.time_left.hours_number, hours_text: res.time_left.hours_text, applyType: 'bank-account', id: el.id }
                  ]
                }
                that.bankAccKey++
              }
            }
          } else if (payload.applyType == 'merchant-account') {
            const data = that.merchantAccountsData.data
            for (var i = 0; i < data.length; i++) {
              const el = data[i]
              const gdpr = { id: el.id, type: 'account-gdpr', gdpr: el.gdpr, serialNumber: el.serial_number, applyType: 'merchant-account' }
              if (el.id === payload.id) {
                this.$noty.success("Apply status was successfully changed")
                if (!res.applied) {
                  that.merchantAcc.body[i] = [
                    el.serial_number, el.license_type, el.country_code, el.zone_type,
                    that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, el.onboarding_time_text, { type: 'account-apply-btn', html: 'Apply', applyType: 'merchant-account', id: el.id, gdpr: el.gdpr }
                  ]
                } else {
                  that.merchantAcc.body[i] = [
                    el.serial_number, el.license_type, el.country_code, el.zone_type,
                    that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, gdpr, { type: 'deadline_2', days: res.time_left.days_number, days_text: res.time_left.days_text, hours: res.time_left.hours_number, hours_text: res.time_left.hours_tex, applyType: 'merchant-account', id: el.id }
                  ]
                }
                that.merchantAccKey++
              }
            }
          }

        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    handleFileInvoiceChange ($event) {
      const that = this
      if ($event.target.files.length > 0) {
        const formData = new FormData()
        formData.append('file', $event.target.files[0])
        axios.post( process.env.VUE_APP_BACKEND_API_URL + 'user/invoice',
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function(response){
          if (response.data.success) {
            if (response.data.invoice) {
              that.invoice.data = response.data.invoice
              that.invoice.pdfUrl = process.env.VUE_APP_BACKEND_URL + '/storage/invoices/' + response.data.invoice.file
            }
            that.pdfObjectKey++
            that.$noty.success('You successfully uploaded invoice')
          }
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
      }
    }
  },
  watch: {
    '$store.state.user': function() {
      this.showCalculatedRisk = (this.$store.state.user && this.$store.state.user.risk) ? this.$store.state.user.show_calculated_risk : false;
      this.calculatedRiskName = (this.$store.state.user && this.$store.state.user.calculatedRisk && typeof this.$store.state.user.calculatedRisk === 'object') ? this.$store.state.user.calculatedRisk.name : '';
      this.risk = (this.$store.state.user && this.$store.state.user.risk) ? this.$store.state.user.risk : '';
    }
  }
}
</script>

<style lang="scss" scoped>
.page-accounts {
  position: relative;

  .box {
    background-color: #000000;

    &_selectedAcc {
      .header {
        .title {
          text-align: center;

          &::before {
            content: none;
          }
        }
      }

      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }

          ::v-deep .tbl {
            position: relative;
            background-color: #000000;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(124.81deg, #323940 0%, rgba(50, 57, 64, 0.9) 46.79%, rgba(0, 0, 0, 0.02) 100%);
              box-shadow:
                20px 30px 100px rgba(0, 0, 0, 0.05),
                inset -5px -5px 10px rgba(255, 255, 255, 0.05),
                inset 5px 5px 10px rgba(255, 255, 255, 0.05);
              backdrop-filter: blur(20px);
              opacity: 0.5;
            }

            > * {
              position: relative;
            }
          }

          &__bankAccount {
            .hdr {
              ::v-deep svg.img {
                path {
                  fill: #24BAD6;
                }
              }
            }

            ::v-deep .tbl {
              &_header {
                background-color: rgba(36, 187, 214, 0.5);
              }
            }
          }

          &__merchantAccount {
            .hdr {
              ::v-deep svg.img {
                path {
                  fill: #FF056C;
                }
              }
            }

            ::v-deep .tbl {
              &_header {
                background-color: rgba(255, 5, 109, 0.5);
              }
            }
          }
        }
      }
    }

    &_invoice {
      width: 100%;
      background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);

      .header {
        display: flex;
        align-items: center;
        padding: 3rem 0;

        &_left, &_right {
          width: 27%;
          padding: 0;
        }

        &_center {
          flex-grow: 1;

          .doc {
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 42%;
            height: 88%;
            // background-color: #ffffff;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;

            &_close {
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
              width: 2.5rem;
              height: 2.5rem;
              background-color: #B03636;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 45%;
                height: 45%;
              }
            }

            &.box__form {
              height: auto;
              min-height: 40%;
              display: flex;
              flex-direction: column;
              color: #22252B;
              background-color: #f0f4f8;

              .header {
                padding: 1.5rem 0 1rem;
                padding: 2vh 0 1.5vh;

                .title {
                  color: #22252B;

                  &::before {
                    background-color: #22252B;
                  }
                }
              }

              .body {
                flex-grow: 1;
                padding: 1.75rem 2.5rem 1rem;
                padding: 1.75rem 2vw 1rem;

                .row {
                  display: flex;
                  align-items: center;
                  padding: .5rem 0;

                  .item {
                    &__half {
                      width: 50%;

                      &--l {
                        padding-right: .75rem;
                      }

                      &--r {
                        padding-left: .75rem;
                      }
                    }
                  }

                  .label {
                    font-weight: bold;
                    min-width: 10rem;
                    padding: .5rem 1rem .5rem 0;
                  }

                  input, select {
                    &::placeholder {
                      color: rgba(0, 0, 0, 0.6);
                    }

                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                    }

                    &:focus {
                      outline: none;
                    }
                  }

                  .inpt {
                    height: 3.5rem;
                    color: #22252B;
                    font-size: 20px;
                    border: none;
                    border-radius: 1rem;
                    padding: .5rem .75rem .5rem 1rem;
                    background-color: #fff;

                    &__price {
                      display: flex;
                      align-items: center;
                      padding: .25rem .25rem .25rem .75rem;

                      .val {
                        flex-grow: 1;
                        min-width: 5rem;
                        padding-right: 1rem;
                        text-align: center;
                        font-size: 1.5rem;
                        font-weight: bold;
                      }

                      .btn {
                        &__currency {
                          height: 100%;
                          padding: .25rem .5rem;
                          color: #ffffff;
                          background-color: #22252B;
                          border: none;
                          border-radius: .75rem;
                        }
                      }
                    }

                    &__services {
                      height: auto;
                      padding: .5rem .75rem;
                      text-align: center;
                      white-space: break-spaces;
                    }

                    &__country {
                      max-width: 100%;
                    }

                    &__packageName {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }

                  &__services {
                    align-items: flex-start;
                    flex-wrap: wrap;

                    .h2 {
                      width: 100%;
                      font-size: 1.25rem;
                      padding: .5rem .25rem .25rem;
                      text-align: center;
                      text-transform: uppercase;
                    }
                  }

                  &__btns {
                    display: flex;
                    justify-content: center;
                    padding-top: .25rem;
                    padding-bottom: .75rem;

                    .btn {
                      color: #fff;
                      font-size: 18px;
                      padding: 1rem 3rem;
                      margin: .25rem 1rem;
                      background: linear-gradient(113.24deg,#13b497 16.01%,#05737a 106.71%);
                      box-shadow: 0 12px 23px rgba(62,73,84,.04);
                      border-radius: 6px;
                    }
                  }

                  &__back {
                    padding-top: 0;
                    margin-top: -1rem;
                  }

                  &__info {
                    justify-content: center;
                    padding-bottom: .75rem;
                    text-align: center;
                    font-size: 1.25rem;
                    font-weight: bold;
                  }

                  ::v-deep .checkBox {
                    .check svg path {
                      fill: #000000;
                    }
                  }

                  &__invoice {
                    object {
                      width: 100%;
                      height: 25vh;

                      embed {
                        border-radius: unset;
                      }
                    }
                  }

                  &__buy {


                    .btn {
                      padding: .75rem 5rem;
                      margin: 1rem auto;
                      color: #fff;
                      font-weight: 500;
                      font-size: 24px;
                      background-color: #7d92ee;
                      text-transform: uppercase;
                      border-radius: 1rem;
                      transition: .3s;

                      &:hover {
                        background-color: #6c56ca;
                      }
                    }
                  }
                }

                &__card {
                  .row {
                    .label {
                      min-width: 13.5rem;
                    }
                  }
                }

                .title {
                  &__btns {
                    width: 100%;
                    font-size: 1.25rem;
                    padding: 0.5rem 0.25rem 0;
                    text-align: center;
                    text-transform: uppercase;
                  }
                }
              }
            }

            &.box__info {
              height: auto;
              min-height: 35%;
              display: flex;
              flex-direction: column;
              color: #22252B;
              background-color: #f0f4f8;

              .body {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
              }
            }
          }

          embed {
            width: 100%;
            height: 100%;
            border-radius: 14px;
          }
        }

        &_left {
          * {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            padding-left: 2vw;
            padding-right: 2vw;
          }
        }

        &_right {
          text-align: center;

          .btn {
            &_uploadPayment {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 1rem 3rem;
              background: #22252B;
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 1.5rem 3rem;
  }
}

.pagination {
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}

.risk-title {
  margin-right: 10px;
}
</style>